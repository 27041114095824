const getPostOptions = data => ({
	method: 'POST',
	headers: {
		'Content-Type': 'application/x-www-form-urlencoded',
	},
	mode: 'no-cors',
	credentials: 'include',
	body: data,
});

// eslint-disable-next-line no-return-await
export const apiGet = async url => await fetch(url)
		.then(response => response)
		.then(data => data);

export const apiPost = async (url, data) => {
	await fetch(url, getPostOptions(data));
};
